import { A } from "@solidjs/router";
import type { IconTypes } from "solid-icons";
import { TbLoader2 } from "solid-icons/tb";
import { type ParentComponent, Show, children, createMemo } from "solid-js";
import { Dynamic } from "solid-js/web";
import { twMerge } from "tailwind-merge";

type StButtonProps = {
  /**
   * @default "md"
   */
  size?: "xs" | "sm" | "md" | "lg";
  type?: "button" | "submit" | "reset";
  icon?: IconTypes;
  /**
   * @default "left"
   */
  iconPlacement?: "left" | "right";
  /**
   * If the button is only an icon button, provide a label for the button
   */
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  class?: string;
  simple?: boolean;
  iconClass?: string;
  onClick?: (e: MouseEvent) => void;
  href?: string;
};

const mapper = {
  xs: {
    base: "text-2xs h-5",
    icon: "size-3",
    iconOnly: "size-3",
    square: "size-5",
    regular: "px-1 gap-1",
  },
  sm: {
    base: "text-xs",
    icon: "size-[13px]",
    iconOnly: "size-4",
    square: "size-6",
    regular: "px-2 gap-1",
  },
  md: {
    base: "text-sm overflow-y-hidden",
    icon: "size-4",
    iconOnly: "size-5",
    square: "size-8",
    regular: "px-3 py-1 gap-2 h-8",
  },
  lg: {
    base: "text-base overflow-y-hidden",
    icon: "size-4",
    iconOnly: "size-5",
    square: "size-11",
    regular: "px-4 py-2 gap-2 h-11",
  },
};
export const StButton: ParentComponent<StButtonProps> = (props) => {
  const resolved = children(() => props.children);
  const sizes = createMemo(() => {
    const size = props.size || "md";
    const c = [mapper[size].base];
    const iconOnly = props.icon && !resolved();
    if (iconOnly) c.push(mapper[size].square);
    else c.push(mapper[size].regular);
    return {
      btn: c.join(" "),
      icon: iconOnly ? mapper[size].iconOnly : mapper[size].icon,
    };
  });

  return (
    <Dynamic
      component={props.href ? A : "button"}
      href={props.href}
      aria-label={props.label}
      title={props.label}
      disabled={props.disabled}
      type={props.type || "button"}
      onClick={props.onClick}
      class={twMerge(
        "inline-flex justify-center items-center transition-all text-nowrap",
        "rounded disabled:cursor-not-allowed",
        !props.simple && "shadow-sm",
        // text
        "text-slate-900 disabled:text-slate-500 dark:text-white dark:disabled:text-slate-500",
        // bg
        !props.simple && "bg-white disabled:bg-slate-100 dark:bg-indigo-950 dark:disabled:bg-indigo-1100",
        // border
        !props.simple &&
          "border border-slate-200 disabled:border-slate-200 dark:border-indigo-800 dark:disabled:border-slate-800",
        // hover
        "hover:bg-slate-100 dark:hover:bg-indigo-900 hover:border-slate-500 dark:hover:border-indigo-500",
        sizes().btn,
        props.class,
        props.iconPlacement === "right" ? "flex-row-reverse" : false,
      )}
    >
      <Show when={props.icon}>
        <Dynamic
          component={props.loading ? TbLoader2 : props.icon}
          class={twMerge("flex-shrink-0", sizes().icon, props.loading && "animate-spin", props.iconClass)}
        />
      </Show>
      <Show when={resolved()}>
        <span>{resolved()}</span>
      </Show>
    </Dynamic>
  );
};
